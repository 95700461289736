import React, { useState, useEffect, RefObject, useRef } from "react";
import styles from "./Navbar.module.css";
import logo from "../../assets/images/logo.png";
import RoundedButton from "../../UI/RoundedButton/RoundedButton";
import navbarBlogger from "../../assets/images/navbarBlogger.png";
import { FiMenu } from "react-icons/fi";
import { useNavigate } from "react-router";

interface NavbarProps {
    children?: React.ReactElement;
}


function useOutsideClick<T extends HTMLElement>(
    ref: RefObject<T>,
    callback: () => void
) {
    useEffect(() => {
        function handleClickOutside(event: MouseEvent) {
            if (ref.current && !ref.current.contains(event.target as Node)) {
                callback();
            }
        }

        // Bind the event listener
        document.addEventListener('mousedown', handleClickOutside);
        return () => {
            // Unbind the event listener on clean up
            document.removeEventListener('mousedown', handleClickOutside);
        };
    }, [ref, callback]);
}


const Navbar: React.FC<NavbarProps> = () => {

    const [isDesktop] = useState<boolean>(window.innerWidth >= 600);
    const [isMenuActive, setMenu] = useState<boolean>(false);
    const navigate = useNavigate()

    const wrapperRef = useRef(null);

    useOutsideClick(wrapperRef, () => {
        isMenuActive && activeMenu()
    })

    const activeMenu = () => {
        setMenu((state) => !state);
    }


    return (
        <div>
            <div className={styles.navbarBlankLayer} />
            <div className={styles.navbar}>
                {
                    isDesktop ? <>
                        <img onClick={() => navigate("/")} src={logo} className={styles.logo} draggable={false} />
                        <div><b><a href="#quem_somos">QUEM SOMOS</a></b></div>
                        <div><b><a href="#projeto_pedagogico">PROJETO PEDAGÓGICO</a></b></div>
                        <div><b><a href="#clientes">CLIENTES</a></b></div>
                        <div><b><a href="#imprensa">IMPRENSA</a></b></div>
                        <div><b><a href="https://portaldogomaker.com.br">PORTAL DO PROFESSOR</a></b></div>
                        <RoundedButton onClick={() => navigate('/downloads')}>
                            {/* <a href="https://drive.google.com/file/d/1Qn1CIIynHmRR6Ub7S6y01gq0YegU4cWf/view?pli=1" target="_blank">DOWNLOADS</a> */}
                            DOWNLOADS
                        </RoundedButton>
                        {/* <a href="https://blog.editoradogomaker.com" ><img src={navbarBlogger} width={64} /></a> */}
                    </> : <div className={styles.mobileNavbar}>
                        <img onClick={() => navigate("/")} src={logo} className={styles.logo} draggable={false} />
                        <RoundedButton>
                            <a href="https://portaldogomaker.com.br">
                                PORTAL
                            </a>

                        </RoundedButton>
                        <FiMenu onClick={activeMenu} />

                    </div>
                }



            </div>
            <div style={{
                display: isMenuActive ? 'block' : 'none'
            }} className={styles.menu} ref={wrapperRef}>
                <div><b><a href="#quem_somos" onClick={activeMenu}>QUEM SOMOS</a></b></div>
                <div><b><a href="#projeto_pedagogico" onClick={activeMenu}>PROJETO PEDAGÓGICO</a></b></div>
                <div><b><a href="#clientes" onClick={activeMenu}>CLIENTES</a></b></div>
                <div><b><a href="#imprensa" onClick={activeMenu}>IMPRENSA</a></b></div>
                <div>
                        <RoundedButton onClick={() => navigate('/downloads')}>
                            {/* <a href="https://drive.google.com/file/d/1Qn1CIIynHmRR6Ub7S6y01gq0YegU4cWf/view?pli=1" target="_blank">DOWNLOADS</a> */}
                            DOWNLOADS
                        </RoundedButton>
                </div>
            </div>
        </div>
    )
}

export default Navbar;