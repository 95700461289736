import Banner from "../Components/Banner/Banner"
import Cases from "../Components/Cases/Cases"
import Costumers from "../Components/Costumers/Costumers"
import Earth from "../Components/Earth/Earth"
import Footer from "../Components/Footer/Footer"
import IntroSection from "../Components/IntroSection/IntroSection"
import Materials from "../Components/Materials/Materials"
import Navbar from "../Components/Navbar/Navbar"
import Onu from "../Components/Onu/Onu"
import Press from "../Components/Press/Press"
import Testimonals from "../Components/Testimonials/Testimonials"
import Values from "../Components/Values/Values"

export const HomeRoute = () => {
    return (
        <>
      <Navbar />
      <Banner />
      <IntroSection />
      <Onu />
  <Values />

      <Materials />
      <Earth />
      <Costumers />
      
      <Press />
      <Testimonals />
      <Cases />
      <Footer />
    </>
    )
}