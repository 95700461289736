import Navbar from "../../Components/Navbar/Navbar"
import styles from "./downloads.module.css";
import CapaDogocodeDownload from '../../assets/images/capa-download_dogocode.png';
import CapaDogoblockDownload from '../../assets/images/capa-download_dogoblock.png';
import LogoWin from '../../assets/icons/logo-windows.svg';
import IconDownload from '../../assets/icons/icon-download.svg';
import Footer from "../../Components/Footer/Footer";

export const DownloadPage = () => {
    const handleDownload = (urlDownload: string) => {
        const link = document.createElement("a");
        link.href = urlDownload;
        link.download = "dogo-block-installer.exe"; // Nome do arquivo ao salvar
        link.target = "_blank"; // Opcional, abre em uma nova aba
        link.click();
      };

    return (
        <div>
            <Navbar />
            <div className={styles.container}>
            <h1 className={styles.title}>Faça o download aqui!</h1>
            <div className={styles.cardList}>
            <div className={styles.card}>
                    <div className={styles.imgContainerCode} style={{ backgroundImage: `url(${CapaDogocodeDownload})` }}>
                        
                    </div>
                    {/* <img className={styles.cardImg} src={CapaDogocodeDownload} alt="capa dogo code" /> */}
                    <div className={styles.cardBody}>
                        <div className={styles.cardBodyContent}>
                            <h2 className={styles.cardTitle}>Versão Online  <span className={styles.cardTextoDestaqueCode}> DoGo Code</span></h2>
                            <p>Software de programação visual para o controle da placa <b>micro:bit</b> .</p>
                        </div>
                        <div className={styles.cardBodyAction}>
                            <span className={styles.cardBodyBadge}>
                            {/* <img className={styles.cardLogo} src={LogoWin} alt="windows logo" />
                            <p>Windows</p> */}
                            </span>
                            <button onClick={() => window.location.href="https://educarbr11.github.io/pxt-microbit-dogo"} className={styles.btnDownload}>
                                {/* <img src={IconDownload} alt="icon" /> */}
                                DoGo Code Online
                            </button>
                        </div>
                    </div>
                </div>
                <div className={styles.card}>
                    <div className={styles.imgContainerCode} style={{ backgroundImage: `url(${CapaDogocodeDownload})` }}>
                        
                    </div>
                    {/* <img className={styles.cardImg} src={CapaDogocodeDownload} alt="capa dogo code" /> */}
                    <div className={styles.cardBody}>
                        <div className={styles.cardBodyContent}>
                            <h2 className={styles.cardTitle}>Instalação  <span className={styles.cardTextoDestaqueCode}> DoGo Code</span></h2>
                            <p>Software de programação visual para o controle da placa <b>micro:bit</b> .</p>
                        </div>
                        <div className={styles.cardBodyAction}>
                            <span className={styles.cardBodyBadge}>
                            <img className={styles.cardLogo} src={LogoWin} alt="windows logo" />
                            <p>Windows</p>
                            </span>
                            <button onClick={() => handleDownload("https://github.com/educarbr11/deskdogocode/releases/download/app-v1.0.6/DoGo.Code_1.0.6_x64_en-US.msi")} className={styles.btnDownload}>
                                <img src={IconDownload} alt="icon" />
                                Baixar o DoGo Code
                            </button>
                        </div>
                    </div>
                </div>
                <div className={styles.card}>
                <div className={styles.imgContainerBlock} style={{ backgroundImage: `url(${CapaDogoblockDownload})` }}></div>
                    <div className={styles.cardBody}>
                        <div className={styles.cardBodyContent}>
                            <h2 className={styles.cardTitle}>Instalação <span className={styles.cardTextoDestaqueBlock}> DoGo Block </span></h2>
                            <p>Software de programação visual para o controle da placa <b>Arduino</b>  e criação de <b>jogos</b> .</p>
                        </div>
                        <div className={styles.cardBodyAction}>
                            <span className={styles.cardBodyBadge}>
                            <img className={styles.cardLogo} src={LogoWin} alt="windows logo" />
                            <p>Windows</p>
                            </span>
                            <button onClick={() => handleDownload("https://drive.google.com/file/d/1Qn1CIIynHmRR6Ub7S6y01gq0YegU4cWf/view?pli=1")} className={styles.btnDownload}>
                                <img src={IconDownload} alt="icon" />
                                Baixar o DoGo Block
                            </button>
                        </div>
                    </div>
                </div>
            </div>
        </div>



        <Footer />
        </div>
    )
}