import { Route, Routes } from "react-router";
import { HomeRoute } from "./routes/home";
import { DownloadPage } from "./routes/Downloads/downloads";


function App() {
  return (
    <div className="App">
    <Routes>
      <Route path="/" element={<HomeRoute />}/>
      <Route path="/downloads" element={<DownloadPage />}/>
    </Routes>
    </div>
  );
}

export default App;
